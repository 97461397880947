<template>
  <b-table
    :items="users"
    :fields="fields"
    :table-class="'patientsHeadClass'"
    responsive
    striped
    borderless>
    <template v-slot:cell(name)="row">
      {{ `${row.item.name} ${row.item.last_name}` }}
    </template>
    <template v-slot:cell(clinics)="row">
      <span v-for="clinic in row.item.clinics" :key="`${clinic.name}-clinics`">
        {{ clinic.name }}
      </span>
    </template>
    <template v-slot:cell(user_type)="row">
      <p
        :class="[
          formatUserType(row.item.user_type) == 'Externos' ? 'mb-0' : '',
        ]"
        class="m-0">
        {{ formatUserType(row.item.user_type) }}
      </p>
      <p
        class="my-0 mb-0"
        v-if="formatUserType(row.item.user_type) == 'Externos'">
        {{ formatExternalType(row.item.user_type) }}
      </p>
    </template>
    <template v-slot:cell(days)="row">
      <div v-if="row.item.days">
        <p v-for="(day, index) in row.item.days" :key="index + row.item.id">
          {{
            `${days[index - 1]} ${day.from ? day.from : "N/A"} - ${
              day.to ? day.to : "N/A"
            }`
          }}
        </p>
      </div>
      <p v-else>-</p>
    </template>
    <template v-slot:cell(actions)="row">
      <font-awesome-icon
        class="tableIcon"
        @click="editUser(row.item)"
        :icon="['fa', 'pencil-alt']" />
      <font-awesome-icon
        class="tableIcon mx-3"
        @click="pwdEdit(row.item.id)"
        :icon="['fa', 'key']" />
      <font-awesome-icon
        class="tableIcon"
        @click="toggleUser(row.item.id)"
        :icon="['fa', `${row.item.is_active ? 'toggle-on' : 'toggle-off'}`]" />
    </template>
  </b-table>
</template>

<script>
// @ is an alias to /src
import { userTypesList, externalTypesList } from "@/constants/settings";

export default {
  name: "UsersList",
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userTypesList,
      externalTypesList,
      days: ["Lun:", "Mar:", "Mie:", "Jue:", "Vie:", "Sáb:", "Dom:"],
      clinicsList: [
        { id: 3, name: "CDMX" },
        { id: 1, name: "MTY" },
        { id: 2, name: "Puebla" },
      ],
      fields: [
        { class: "text-center", key: "username", label: "Usuario" },
        { class: "text-center", key: "name", label: "Nombre" },
        { class: "text-center", key: "email", label: "Correo" },
        { class: "text-center", key: "clinics", label: "Sucursal" },
        { class: "text-center", key: "user_type", label: "Tipo de usuario" },
        { class: "text-center", key: "days", label: "Horario" },
        {
          class: "text-center",
          key: "identification_card",
          label: "ID Clinico",
        },
        { class: "text-center", key: "actions", label: "Acciones" },
      ],
    };
  },
  methods: {
    formatUserType(userType) {
      const userTypeFormat = userType.includes("external")
        ? userType.slice(0, 8)
        : userType;
      const response = this.userTypesList.find(
        (type) => type.value === userTypeFormat
      );
      return response.text;
    },
    formatExternalType(userType) {
      const userTypeFormat = userType.includes("external")
        ? userType.slice(9)
        : userType;
      const response = this.externalTypesList.find(
        (type) => type.value === userTypeFormat
      );
      return response.text;
    },
    clinicNameFormat(id) {
      if (typeof id === "number") {
        return this.clinicsList.find((clinic) => clinic.id === id).name;
      }
      return this.clinicsList.find((clinic) => clinic.id === id.value).name;
    },
    editUser(user) {
      this.$emit("editUser", user);
    },
    toggleUser(id) {
      this.$emit("toggleUser", id);
    },
    pwdEdit(id) {
      this.$emit("pwdEdit", id);
    },
  },
};
</script>
