var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"hide-footer":"","hide-header":"","size":"lg","id":"CEUser","no-close-on-backdrop":"","no-close-on-esc":""}},[_c('div',{staticClass:"px-5 pb-5 pt-4"},[_c('div',[_c('div',{staticClass:"titles"},[_vm._v(_vm._s(_vm.titleList[_vm.action]))])]),(_vm.action != 'toggleUser')?_c('fieldset',[(_vm.action != 'password')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Sucursal")]),_c('MultiSelect',{attrs:{"multiple":true,"options":_vm.clinicsData,"track-by":"name","label":"name","value":"value","placeholder":"Sucursal"},model:{value:(_vm.user.clinics_ids),callback:function ($$v) {_vm.$set(_vm.user, "clinics_ids", $$v)},expression:"user.clinics_ids"}})],1)]),(_vm.user.clinics_ids)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Tipo se usuario")]),_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.userTypeData,"state":!_vm.$v.user.user_type.$invalid,"value-field":"value","text-field":"text","placeholder":"Tipo de usuario"},on:{"change":function($event){_vm.$nextTick(function () {
                  _vm.$v.$reset();
                })}},model:{value:(_vm.user.user_type),callback:function ($$v) {_vm.$set(_vm.user, "user_type", $$v)},expression:"user.user_type"}})],1)]):_vm._e(),(_vm.user.user_type === 'external')?_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Tipo se externo")]),_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.externalTypesList,"state":!_vm.$v.user.external_type.$invalid,"value-field":"value","text-field":"text","placeholder":"Tipo de externo"},on:{"change":function($event){_vm.$nextTick(function () {
                  _vm.$v.$reset();
                })}},model:{value:(_vm.user.external_type),callback:function ($$v) {_vm.$set(_vm.user, "external_type", $$v)},expression:"user.external_type"}})],1)]):_vm._e(),(!_vm.$v.user.user_type.$invalid && _vm.isExternalTypeSelected)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"col-form-label mt-4"},[_vm._v("Nombre")]),_c('b-form-input',{staticClass:"form-control",attrs:{"state":!_vm.$v.user.name.$invalid,"type":"text","placeholder":"Nombre"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"col-form-label mt-4"},[_vm._v("Apellido")]),_c('b-form-input',{staticClass:"form-control",attrs:{"state":!_vm.$v.user.last_name.$invalid,"type":"text","placeholder":"Apellido"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Email")]),_c('b-form-input',{staticClass:"form-control",attrs:{"state":!_vm.$v.user.email.$invalid,"type":"email","placeholder":"email@example.com"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Username")]),_c('b-form-input',{staticClass:"form-control",attrs:{"state":!_vm.$v.user.username.$invalid,"type":"text"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)])]:_vm._e()]:_vm._e(),(
          (_vm.action != 'edit' &&
            !_vm.$v.user.user_type.$invalid &&
            _vm.isExternalTypeSelected) ||
          _vm.action == 'password'
        )?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Contraseña")]),_c('b-form-input',{staticClass:"form-control",attrs:{"state":!_vm.$v.user.password.$invalid,"type":"password","placeholder":"Contraseña"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),(_vm.$v.user.password.$invalid && !_vm.$v.user.password.required)?_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" La contraseña es requisito ")]):_vm._e(),(_vm.$v.user.password.$invalid && !_vm.$v.user.password.minLength)?_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" Mínimo 6 cáracters ")]):_vm._e()],1),_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Confirmar contraseña")]),_c('b-form-input',{staticClass:"form-control",attrs:{"type":"password","state":!_vm.$v.user.password_confirmation.$invalid,"placeholder":"Contraseña"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),(
                _vm.$v.user.password_confirmation.$invalid &&
                !_vm.$v.user.password_confirmation.required
              )?_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" La contraseña es requisito ")]):_vm._e(),(
                _vm.$v.user.password_confirmation.$invalid &&
                !_vm.$v.user.password_confirmation.minLength
              )?_c('b-form-invalid-feedback',{attrs:{"id":"input-live-feedback"}},[_vm._v(" Mínimo 6 cáracters ")]):_vm._e()],1)])]:_vm._e(),(
          _vm.requireIdentificationCard &&
          _vm.action != 'password' &&
          _vm.isExternalTypeSelected
        )?[_c('div',{staticClass:"row"},[(_vm.isDrUser)?_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Titulo")]),_c('fieldset',{staticClass:"d-flex"},[_c('div',{staticClass:"col-3"},[_c('b-form-radio',{staticClass:"ms-2",attrs:{"state":!_vm.$v.user.title.$invalid,"type":"radio","name":"optionsRadios","value":"dr","checked":""},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}},[_c('span',{staticClass:"ps-2"},[_vm._v("Dr")])])],1),_c('div',{staticClass:"col-3"},[_c('b-form-radio',{attrs:{"state":!_vm.$v.user.title.$invalid,"type":"radio","name":"optionsRadios","value":"dra"},model:{value:(_vm.user.title),callback:function ($$v) {_vm.$set(_vm.user, "title", $$v)},expression:"user.title"}},[_c('span',{staticClass:"ps-2"},[_vm._v("Dra")])])],1),_c('div',{staticClass:"col-12"},[_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.user.title.$invalid}},[_vm._v("Seleccione titulo")])],1)])]):_vm._e(),_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Cedula")]),_c('b-form-input',{staticClass:"form-control",attrs:{"state":!_vm.$v.user.identification_card.$invalid,"placeholder":"Cedula"},model:{value:(_vm.user.identification_card),callback:function ($$v) {_vm.$set(_vm.user, "identification_card", $$v)},expression:"user.identification_card"}})],1)]),_c('hr')]:_vm._e(),(_vm.requireSchedule)?_vm._l((_vm.user.days),function(day,index){return _c('div',{key:index + 'title'},[_c('div',{staticClass:"titles mb-0"},[_vm._v(" "+_vm._s(_vm.days[index - 1])+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Horario inicial")]),_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.hoursData,"placeholder":"Inicio"},on:{"change":function($event){_vm.resetAndTouchData();
                  _vm.updateHours('from', index);}},model:{value:(_vm.user.days[index].from),callback:function ($$v) {_vm.$set(_vm.user.days[index], "from", $$v)},expression:"user.days[index].from"}})],1),_c('div',{staticClass:"col form-group"},[_c('label',{staticClass:"form-label mt-4"},[_vm._v("Horario final")]),_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.hoursData,"value-field":"value","text-field":"text","placeholder":"Fin"},on:{"change":function($event){_vm.resetAndTouchData();
                  _vm.updateHours('to', index);}},model:{value:(_vm.user.days[index].to),callback:function ($$v) {_vm.$set(_vm.user.days[index], "to", $$v)},expression:"user.days[index].to"}})],1)]),_c('hr')])}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-6 form-group"},[_c('button',{staticClass:"form-control btn btn-secondary",attrs:{"type":"submit"},on:{"click":_vm.cancelCEUser}},[_vm._v(" Cancelar ")])]),_c('div',{staticClass:"col-6 form-group"},[_c('button',{staticClass:"form-control btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.sendData}},[_vm._v(" Enviar ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }