<template>
  <form
    autocomplete="off"
    class="
      row
      justify-content-between justify-content-md-end justify-content-lg-between
      align-items-center
    "
    @submit.stop.prevent>
    <div
      class="
        d-flex
        justify-content-md-end
        col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5
        mb-3
      ">
      <div class="d-flex col-12 col-md-6 col-lg-12">
        <b-form-input
          @keyup.enter="$emit('filterUsers')"
          v-model="search"></b-form-input>
        <b-input-group-append>
          <button
            @click="$emit('filterUsers')"
            class="btn btnSearch searchBtnIcon h-100"
            variant="primary">
            Buscar
          </button>
        </b-input-group-append>
      </div>
    </div>
    <b-pagination
      class="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 mb-3"
      v-model="page"
      :total-rows="total"
      @change="changePage($event)"
      :per-page="limit"
      align="right"
      aria-controls="my-table"></b-pagination>
  </form>
</template>

<script>
export default {
  name: "CEUserFilter",
  props: {
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      limit: 10,
      search: "",
    };
  },
  methods: {
    changePage(page) {
      this.$emit("filterUsers", {
        page,
        limit: this.limit,
        search: this.search,
      });
    },
  },
};
</script>
