<template>
  <div class="card p-5 card-citmer cardRadius">
    <div class="d-flex justify-content-end mb-5">
      <div class="p-3 text-white">.</div>
      <b-dropdown text="Administrar usuario">
        <b-dropdown-item @click="createUser()">Crear Usuario</b-dropdown-item>
        <b-dropdown-item @click="editUser(self_user)"
          >Actualizar Usuario</b-dropdown-item
        >
        <b-dropdown-item @click="pwdEdit(self_user.id)"
          >Cambiar Contraseña</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <CEUser
      :key="CEUserKey"
      @resetCEUser="resetCEUser"
      :user_type="userType"
      :action="userAction"
      :user="userData"
      :id="idData" />
    <UserFilter ref="userFilter" @filterUsers="getUsers" :total="total" />
    <UsersList
      :users="usersData"
      @editUser="editUser"
      @toggleUser="toggleUser"
      @pwdEdit="pwdEdit" />
  </div>
</template>

<script>
// eslint-disable no-param-reassign
import CEUser from "@/components/user/CEUser.vue";
import UsersList from "@/components/user/UsersList.vue";
import UserFilter from "@/components/user/UserFilter.vue";
import { mapGetters } from "vuex";

export default {
  name: "CEUsers",
  components: {
    CEUser,
    UsersList,
    UserFilter,
  },
  data() {
    return {
      total: 1,
      users: [],
      self_user: {},
      user_type: "",
      action: "create",
      user: {},
      id: 0,
      CEUserKey: 1,
      clinicsList: [
        { id: 3, name: "CDMX" },
        { id: 1, name: "MTY" },
        { id: 2, name: "Puebla" },
      ],
    };
  },
  mounted() {
    this.getUsers({});
  },
  watch: {
    getClinic() {
      this.getUsers({});
    },
  },
  methods: {
    resetCEUser() {
      this.CEUserKey += 1;
    },
    createUser() {
      this.user = {};
      this.user.days = {};
      this.user.username = null;
      for (let index = 1; index < 8; index += 1) {
        this.user.days[index] = {
          from: "08:00",
          to: "18:00",
        };
      }
      this.user_type = "";
      this.action = "create";
      this.$bvModal.show("CEUser");
    },
    editUser(user) {
      this.user = JSON.parse(JSON.stringify(user));
      this.user_type = this.user.user_type.includes("external")
        ? this.user.user_type.slice(0, 8)
        : this.user.user_type;
      this.user.user_type = this.user.user_type.includes("external")
        ? this.user.user_type.slice(0, 8)
        : this.user.user_type;
      this.user.external_type = user.user_type.includes("external_")
        ? user.user_type.slice(9)
        : "medic";
      this.action = "edit";
      this.$bvModal.show("CEUser");
    },
    toggleUser(id) {
      this.user = {};
      this.id = id;
      this.action = "toggleUser";
      this.$bvModal.show("CEUser");
    },
    pwdEdit(id) {
      this.user = {};
      this.id = id;
      this.action = "password";
      this.$bvModal.show("CEUser");
    },
    getUsers(info) {
      const { page, limit, search } = info || this.$refs.userFilter;
      const payload = {
        clinic_id: this.getClinic,
        search,
        limit,
        page,
      };
      this.$api.user.list(payload).then((res) => {
        this.users = res.data.users;
        this.self_user = res.data.self_user;
        this.users.forEach((user) => {
          // eslint-disable-next-line no-param-reassign
          user.clinics = [];
          user.clinics_ids.forEach((clinicId) => {
            const newClinics = {};
            newClinics.value = clinicId;
            newClinics.name = this.clinicsList.find(
              (clinic) => clinic.id === clinicId
            ).name;
            user.clinics.push(newClinics);
          });
        });
        // eslint-disable-next-line no-param-reassign
        this.total = res.data?.users_total ? res.data?.users_total : this.total;
      });
    },
  },
  computed: {
    ...mapGetters(["getClinic"]),
    usersData() {
      return this.users;
    },
    userData() {
      return this.user;
    },
    userType() {
      return this.user_type;
    },
    userAction() {
      return this.action;
    },
    idData() {
      return this.id;
    },
  },
};
</script>
