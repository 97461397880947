<template>
  <b-modal
    hide-footer
    hide-header
    size="lg"
    id="CEUser"
    no-close-on-backdrop
    no-close-on-esc>
    <div class="px-5 pb-5 pt-4">
      <div>
        <!-- TITLE BY ACTION TYPE -->
        <div class="titles">{{ titleList[action] }}</div>
        <!-- TITLE BY ACTION TYPE /-->
      </div>
      <fieldset v-if="action != 'toggleUser'">
        <template v-if="action != 'password'">
          <div class="row">
            <!-- CLINIC SELECT INPUT -->
            <div class="form-group">
              <label class="form-label mt-4">Sucursal</label>
              <MultiSelect
                :multiple="true"
                v-model="user.clinics_ids"
                :options="clinicsData"
                track-by="name"
                label="name"
                value="value"
                placeholder="Sucursal" />
            </div>
            <!-- CLINIC SELECT INPUT /-->
          </div>
          <div class="row" v-if="user.clinics_ids">
            <!-- USER TYPE SELECT INPUT -->
            <div class="form-group">
              <label class="form-label mt-4">Tipo se usuario</label>
              <b-form-select
                :options="userTypeData"
                v-model="user.user_type"
                :state="!$v.user.user_type.$invalid"
                @change="
                  $nextTick(() => {
                    $v.$reset();
                  })
                "
                value-field="value"
                text-field="text"
                class="form-control"
                placeholder="Tipo de usuario" />
            </div>
            <!-- USER TYPE SELECT INPUT /-->
          </div>
          <div class="row mb-2" v-if="user.user_type === 'external'">
            <!-- USER TYPE SELECT INPUT -->
            <div class="form-group">
              <label class="form-label mt-4">Tipo se externo</label>
              <b-form-select
                :options="externalTypesList"
                v-model="user.external_type"
                :state="!$v.user.external_type.$invalid"
                @change="
                  $nextTick(() => {
                    $v.$reset();
                  })
                "
                value-field="value"
                text-field="text"
                class="form-control"
                placeholder="Tipo de externo" />
            </div>
            <!-- USER TYPE SELECT INPUT /-->
          </div>
          <template
            v-if="!$v.user.user_type.$invalid && isExternalTypeSelected">
            <div class="row">
              <!-- NAME INPUT -->
              <div class="col form-group">
                <label class="col-form-label mt-4">Nombre</label>
                <b-form-input
                  v-model="user.name"
                  :state="!$v.user.name.$invalid"
                  type="text"
                  class="form-control"
                  placeholder="Nombre" />
              </div>
              <!-- NAME INPUT /-->
              <!-- LASTNAME INPUT -->
              <div class="col form-group">
                <label class="col-form-label mt-4">Apellido</label>
                <b-form-input
                  v-model="user.last_name"
                  :state="!$v.user.last_name.$invalid"
                  type="text"
                  class="form-control"
                  placeholder="Apellido" />
              </div>
              <!-- LASTNAME INPUT /-->
            </div>
            <div class="row">
              <!-- EMAIL INPUT -->
              <div class="col form-group">
                <label class="form-label mt-4">Email</label>
                <b-form-input
                  v-model="user.email"
                  :state="!$v.user.email.$invalid"
                  type="email"
                  class="form-control"
                  placeholder="email@example.com" />
              </div>
              <!-- EMAIL INPUT /-->
              <!-- USERNAME INPUT -->
              <div class="col form-group">
                <label class="form-label mt-4">Username</label>
                <b-form-input
                  v-model="user.username"
                  :state="!$v.user.username.$invalid"
                  type="text"
                  class="form-control" />
              </div>
              <!-- USERNAME INPUT /-->
            </div>
          </template>
        </template>
        <template
          v-if="
            (action != 'edit' &&
              !$v.user.user_type.$invalid &&
              isExternalTypeSelected) ||
            action == 'password'
          ">
          <div class="row">
            <!-- PASSWORD INPUT -->
            <div class="col form-group">
              <label class="form-label mt-4">Contraseña</label>
              <b-form-input
                v-model="user.password"
                :state="!$v.user.password.$invalid"
                type="password"
                class="form-control"
                placeholder="Contraseña" />
              <b-form-invalid-feedback
                id="input-live-feedback"
                v-if="$v.user.password.$invalid && !$v.user.password.required">
                La contraseña es requisito
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                id="input-live-feedback"
                v-if="$v.user.password.$invalid && !$v.user.password.minLength">
                Mínimo 6 cáracters
              </b-form-invalid-feedback>
            </div>
            <!-- PASSWORD INPUT /-->
            <!-- PASSWORD CONFIRMATION INPUT -->
            <div class="col form-group">
              <label class="form-label mt-4">Confirmar contraseña</label>
              <b-form-input
                type="password"
                v-model="user.password_confirmation"
                :state="!$v.user.password_confirmation.$invalid"
                class="form-control"
                placeholder="Contraseña" />
              <b-form-invalid-feedback
                id="input-live-feedback"
                v-if="
                  $v.user.password_confirmation.$invalid &&
                  !$v.user.password_confirmation.required
                ">
                La contraseña es requisito
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                id="input-live-feedback"
                v-if="
                  $v.user.password_confirmation.$invalid &&
                  !$v.user.password_confirmation.minLength
                ">
                Mínimo 6 cáracters
              </b-form-invalid-feedback>
            </div>
            <!-- PASSWORD CONFIRMATION INPUT /-->
          </div>
        </template>
        <template
          v-if="
            requireIdentificationCard &&
            action != 'password' &&
            isExternalTypeSelected
          ">
          <div class="row">
            <!-- DR INPUT -->
            <div v-if="isDrUser" class="col form-group">
              <label class="form-label mt-4">Titulo</label>
              <fieldset class="d-flex">
                <div class="col-3">
                  <b-form-radio
                    v-model="user.title"
                    :state="!$v.user.title.$invalid"
                    type="radio"
                    name="optionsRadios"
                    class="ms-2"
                    value="dr"
                    checked>
                    <span class="ps-2">Dr</span>
                  </b-form-radio>
                </div>
                <div class="col-3">
                  <b-form-radio
                    v-model="user.title"
                    :state="!$v.user.title.$invalid"
                    type="radio"
                    name="optionsRadios"
                    value="dra">
                    <span class="ps-2">Dra</span>
                  </b-form-radio>
                </div>
                <div class="col-12">
                  <b-form-invalid-feedback :state="!$v.user.title.$invalid"
                    >Seleccione titulo</b-form-invalid-feedback
                  >
                </div>
              </fieldset>
            </div>
            <!-- DR INPUT /-->
            <!-- IDENTIFICATION CARD INPUT -->
            <div class="col form-group">
              <label class="form-label mt-4">Cedula</label>
              <b-form-input
                v-model="user.identification_card"
                :state="!$v.user.identification_card.$invalid"
                class="form-control"
                placeholder="Cedula" />
            </div>
            <!-- IDENTIFICATION CARD INPUT /-->
          </div>
          <hr />
        </template>
        <template v-if="requireSchedule">
          <div v-for="(day, index) in user.days" :key="index + 'title'">
            <div class="titles mb-0">
              {{ days[index - 1] }}
            </div>
            <div class="row">
              <!-- HOUR INIT INPUT -->
              <div class="col form-group">
                <label class="form-label mt-4">Horario inicial</label>
                <b-form-select
                  :options="hoursData"
                  v-model="user.days[index].from"
                  @change="
                    resetAndTouchData();
                    updateHours('from', index);
                  "
                  class="form-control"
                  placeholder="Inicio" />
              </div>
              <!-- HOUR INIT INPUT /-->
              <!-- HOUR END INPUT -->
              <div class="col form-group">
                <label class="form-label mt-4">Horario final</label>
                <b-form-select
                  :options="hoursData"
                  v-model="user.days[index].to"
                  @change="
                    resetAndTouchData();
                    updateHours('to', index);
                  "
                  value-field="value"
                  text-field="text"
                  class="form-control"
                  placeholder="Fin" />
              </div>
              <!-- HOUR END INPUT /-->
            </div>
            <hr />
          </div>
        </template>
      </fieldset>
      <div class="row mt-4">
        <!-- CANCEL BUTTON -->
        <div class="col-6 form-group">
          <button
            @click="cancelCEUser"
            type="submit"
            class="form-control btn btn-secondary">
            Cancelar
          </button>
        </div>
        <!-- CANCEL BUTTON /-->
        <!-- SUBMIT BUTTON -->
        <div class="col-6 form-group">
          <button
            @click="sendData"
            type="submit"
            class="form-control btn btn-primary">
            Enviar
          </button>
        </div>
        <!-- SUBMIT BUTTON /-->
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  hoursList,
  userTypesList,
  externalTypesList,
} from "@/constants/settings";
import { email, sameAs, requiredIf, minLength } from "vuelidate/lib/validators";
import MultiSelect from "vue-multiselect";

export default {
  name: "CEUser",
  props: {
    action: {
      type: String,
      required: true,
      default: "",
    },
    user_type: {
      type: String,
      required: true,
      default: "medic",
    },
    id: {
      type: Number,
      required: false,
      default: 0,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    MultiSelect,
  },
  data() {
    return {
      titleList: {
        create: "Crear usuario",
        toggleUser: "Cambiar estado del usuario",
        edit: "Editar usuario",
        password: "Cambiar contraseña",
      },
      days: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ],
      userTypesList,
      hoursList,
      externalTypesList,
    };
  },
  validations() {
    const validations = {
      user: {
        user_type: {
          required: requiredIf(function userReq() {
            return this.isCreateAction || this.isEditAction;
          }),
        },
        external_type: {
          required: requiredIf(function externalReq() {
            return (
              (this.isCreateAction || this.isEditAction) &&
              this.user.user_type === "external"
            );
          }),
        },
        name: {
          required: requiredIf(function nameReq() {
            return this.isCreateAction || this.isEditAction;
          }),
        },
        last_name: {
          required: requiredIf(function lastNameReq() {
            return this.isCreateAction || this.isEditAction;
          }),
        },
        email: {
          required: requiredIf(function emailReq() {
            return this.isCreateAction || this.isEditAction;
          }),
          email,
        },
        username: {
          required: requiredIf(function usernameReq() {
            return this.isCreateAction || this.isEditAction;
          }),
        },
        password: {
          minLength: minLength(6),
          required: requiredIf(function passwordReq() {
            return this.isCreateAction || this.isPasswordAction;
          }),
          sameAsPassword: sameAs("password_confirmation"),
        },
        password_confirmation: {
          minLength: minLength(6),
          required: requiredIf(function passwordConfirmReq() {
            return this.isCreateAction || this.isPasswordAction;
          }),
          sameAsPassword: sameAs("password"),
        },
        title: {
          required: requiredIf(function titleReq() {
            return (this.isCreateAction || this.isEditAction) && this.isDrUser;
          }),
        },
        identification_card: {
          required: requiredIf(function idCardReq() {
            return (
              (this.isCreateAction || this.isEditAction) &&
              this.requireIdentificationCard &&
              this.user.user_type !== "nursing" &&
              this.user.user_type !== "laboratory"
            );
          }),
        },
      },
    };
    return validations;
  },
  methods: {
    async resetAndTouchData() {
      await this.$nextTick();
      this.$v.user.$touch();
    },
    updateHours(position, index) {
      const from = position === "from";
      const naTo =
        this.user.days[index].to === null ||
        this.user.days[index].to === "null";
      const naFrom =
        this.user.days[index].from === null ||
        this.user.days[index].from === "null";
      if (from && naTo) this.changeTheOtherNAOption("from", "to", index);
      if (!from && naFrom) this.changeTheOtherNAOption("to", "from", index);
      if (from && naFrom) this.user.days[index].to = null;
      if (!from && naTo) this.user.days[index].from = null;
    },
    // changeTheOtherNAOption
    // position: from or to (string) - position of the option that changed
    // positionNa: from or to (string) - position of the option that will coding change
    // index: 0 to 6 (number) - index of the day
    // Make sure that the option change after change an NA option
    // is always the next one if the position change is from or the previous one if position is to
    changeTheOtherNAOption(position, positionNa, index) {
      let selectedValue = this.hoursList.findIndex(
        (element) => element.value === this.user.days[index][position]
      );
      if (selectedValue === this.hoursList.length && position === "from")
        selectedValue -= 1;
      if (selectedValue === 0 && position === "to") selectedValue += 1;
      this.user.days[index][positionNa] =
        position === "from"
          ? this.hoursList[selectedValue + 1].value
          : this.hoursList[selectedValue - 1].value;
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    cancelCEUser() {
      this.$bvModal.hide("CEUser");
      const clinics = [];
      this.user.clinics_ids.forEach((element) => {
        clinics.push(element.value);
      });
      this.user.clinics_ids = clinics;
      this.$emit("resetCEUser");
    },
    sendData() {
      const payload = this.user;
      const clinics = [];
      if (!this.isDeleteAction && !this.isPasswordAction) {
        this.user.clinics_ids.forEach((element) => {
          clinics.push(element.value);
        });
      }
      payload.clinics_ids = clinics;
      if (this.isFormDataValid()) {
        this.removeData(payload);
        this.actionRequest(payload);
      }
    },
    removeData(payload) {
      if (this.user.user_type === "cacit") {
        delete payload.title;
        delete payload.identification_card;
      }
      if (this.user.user_type === "external") {
        payload.user_type = `${payload.user_type}_${payload.external_type}`;
      }
    },
    finishActionRequest() {
      this.$parent.getUsers();
      this.cancelCEUser();
    },
    actionRequest(payload) {
      if (this.isCreateAction) {
        this.$api.user.saveNewUser(payload).then(() => {
          this.finishActionRequest();
        });
      } else if (this.isEditAction) {
        this.$api.user.saveEditUser(payload.id, payload).then(() => {
          this.finishActionRequest();
        });
      } else if (this.isPasswordAction) {
        this.$api.user
          .pwdChange(this.id, { password: this.user.password })
          .then(() => {
            this.finishActionRequest();
          });
      } else {
        this.$api.user.toggleUser(this.id).then(() => {
          this.finishActionRequest();
        });
      }
    },
  },
  computed: {
    isExternalTypeSelected() {
      if (this.user.user_type === "external") {
        return !this.$v.user.external_type.$invalid;
      }
      return true;
    },
    isPasswordAction() {
      return this.action === "password";
    },
    isCreateAction() {
      return this.action === "create";
    },
    isEditAction() {
      return this.action === "edit";
    },
    isDeleteAction() {
      return this.action === "toggleUser";
    },
    isDrUser() {
      return (
        this.user.user_type === "medic" || this.user.user_type === "external"
      );
    },
    requireIdentificationCard() {
      const requireIdentificationArray = [
        "medic",
        "nutrition",
        "psychology",
        "external",
        "laboratory",
        "nursing",
      ];
      return requireIdentificationArray.includes(this.user.user_type);
    },
    requireSchedule() {
      const requireScheduleArray = [
        "medic",
        "abc",
        "manager_abc",
        "nutrition",
        "psychology",
        "external",
        "nursing",
      ];
      return requireScheduleArray.includes(this.user.user_type);
    },
    clinicsData() {
      const clinics = this.$store.getters.getClinicList;
      if (!clinics) return [];
      const options = [];
      clinics.forEach((clinic) => {
        options.push({ value: clinic.id, name: clinic.name });
      });
      const newValues = [];
      if (this.user.clinics_ids?.length > 0) {
        this.user.clinics_ids.forEach((id) => {
          if (typeof id !== "object") {
            newValues.push(options.find((clinic) => clinic.value === id));
          } else {
            newValues.push(id);
          }
        });
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.user.clinics_ids = newValues;
      }
      return options;
    },
    userTypeData() {
      const userTypes = JSON.parse(JSON.stringify(this.userTypesList));
      if (this.$store.getters.getUserID !== 1) {
        userTypes.splice(1, 1);
      }
      return userTypes;
    },
    hoursData() {
      return this.hoursList;
    },
  },
};
</script>
